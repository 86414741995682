import {AppConfig, BaseApp, BaseAppProps, BaseAppState, getLoginCredentials} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS, lightGray} from "shared/colors";
import {FIREBASE_CONFIG} from "./consts";
import {
  THEME_COLOR_PRIMARY,
  THEME_COLOR_PRIMARY_CONTRAST,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_SECONDARY_CONTRAST
} from "fyneapps-shared/consts";
import {AbstractMain, AbstractTabsMain} from "shared/AbstractMain";
import {FyneappsToolbar} from "fyneapps-shared/FyneappsToolbar";
import {BaseAppPrefs} from "shared/BaseAppPrefs";
import {PluginConfig} from "../shared/types";
import {MembersKey} from "../shared/entities";
import {LoginConfig} from "../shared/Login";
import defaultUserImage from "./res/default_user.png";
import {StyledBoxRow, StyledSpan} from "../shared/StyledComponents";
import {PD_SM, SZ_SMSM} from "../shared/dimens";
import fyneappsStamp from "./res/fyneapps_logotype.png";
import {ProvisionedApp} from "../shared/ProvisionedApp";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  palette: {
    background: {
      default: lightGray,
    },
    primary: {
      main: THEME_COLOR_PRIMARY,
      contrastText: THEME_COLOR_PRIMARY_CONTRAST,
    },
    secondary: {
      main: THEME_COLOR_SECONDARY,
      contrastText: THEME_COLOR_SECONDARY_CONTRAST,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

function createPluginConfig(podId: string): PluginConfig {
  return {
    app: {
      themeOptions: THEME_OPTIONS,
      firebase: FIREBASE_CONFIG,
    },
    membersKey: MembersKey.from(podId),
    autoLogin: getLoginCredentials(),
  }
}

export abstract class FyneappsMain extends AbstractMain {
}

export abstract class FyneappsToolbarMain extends AbstractMain {

  protected createHeaderToolbar(): React.ReactElement | null {
    return <FyneappsToolbar path={this.props.path}/>;
  }
}

export abstract class FyneappsTabsMain extends AbstractTabsMain {

  getPluginConfig() {
    return createPluginConfig("fixme");
  }

  protected createHeaderToolbar(): React.ReactElement | null {
    return <FyneappsToolbar path={this.props.path}/>;
  }
}

class AppPrefs extends BaseAppPrefs {

  private static instance: AppPrefs;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppPrefs();
    }
    return this.instance;
  }
}

export abstract class FyneappsApp extends ProvisionedApp<AppProps, AppState> {

  getAppPrefs<T extends BaseAppPrefs>(): T {
    return AppPrefs.getInstance() as T;
  }

  protected abstract getAppName(): string;

  protected abstract getIconName(): string;

  protected getLoginConfig(): LoginConfig | null | undefined {
    return {
      // provisioning: {
      //   enabled: true,
      // },
      uiProps: {
        bottomDecorator: this.renderLoginDecorator(),
      },
    };
  }

  private renderLoginDecorator(): ReactElement {
    return <StyledBoxRow style={{padding: PD_SM}}>
      <StyledSpan/>
      <img src={fyneappsStamp} style={{height: SZ_SMSM, marginBottom: -SZ_SMSM, opacity: 0.33}}/>
      <StyledSpan/>
    </StyledBoxRow>;
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: this.getAppName(),
      icon: this.getIconName(),
      logo: this.getIconName(),
      stamp: this.getIconName(),
      stampText: this.getAppName(),
      defaultUserImage: defaultUserImage,
      theme: THEME,
      privacyUrl: "",
      termsUrl: "",
      loginConfig: this.getLoginConfig(),
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }
}