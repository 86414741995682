import React, {ReactElement} from 'react';
import {PathComponent} from "../index";
import {THEME} from "../fyneapps-shared/FyneappsApp";
import {AppConfig, BaseApp, BaseAppProps, BaseAppState, getLoginCredentials} from "../shared/BaseApp";
import {StyledBoxColumn, StyledContainer} from "../shared/StyledComponents";
import {PD_XLG} from "../shared/dimens";
import defaultUserImage from "../fyneapps-shared/res/default_user.png";
import {FIREBASE_CONFIG, THEME_COLOR_PRIMARY} from "../fyneapps-shared/consts";
import {Card, Typography} from "@mui/material";
import {User} from "@firebase/auth";
import {IdRequestConfig, IdResponseConfig} from "../shared/types";
import {JSON_OBJECT} from "../shared/json/helpers";

const TIMEOUT_SEC = 5;

type AppState = BaseAppState & {
  timeoutSec?: number,
}

export class App extends BaseApp<BaseAppProps, AppState> {

  onCreateAppConfig(): AppConfig {
    return {
      name: this.getAppName(),
      icon: this.getIconName(),
      logo: this.getIconName(),
      stamp: "/stamp.png",
      defaultUserImage: defaultUserImage,
      theme: THEME,
      privacyUrl: "",
      termsUrl: "",
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    const idRequestConfig = App.readIdRequestConfig();
    if (idRequestConfig?.autoClose) {
      this.updateTimeout(Number.isInteger(idRequestConfig.autoCloseTimeout) ? idRequestConfig.autoCloseTimeout : TIMEOUT_SEC);
    }
    // TODO: Check that host is one from pre-approved list (fyneapps.com, fyneapps.net etc.)
    const loginCredentials = getLoginCredentials();
    if (window.opener) {
      const idResponseConfig = new IdResponseConfig();
      idResponseConfig.autoLogin = loginCredentials;
      window.opener.postMessage(JSON_OBJECT.serializeObject(idResponseConfig), idRequestConfig?.host);
    }
  }

  private getAppName(): string {
    return "Fyneapps";
  }

  private getIconName(): string {
    return "/logo512.png";
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  private updateTimeout(timeoutSec: number) {
    this.setState({
      timeoutSec: timeoutSec,
    });
    if (timeoutSec <= 0) {
      window.close();
    } else {
      setTimeout(() => this.updateTimeout(timeoutSec - 1), 1000);
    }
  }

  private static readIdRequestConfig(): IdRequestConfig | null {
    const search = window.location.search;
    if (search) {
      const params = new URLSearchParams(search.substring(1));
      const iqcfg = params.get("iqcfg") as string;
      if (iqcfg) {
        return JSON_OBJECT.deserializeObject(JSON.parse(atob(iqcfg)), IdRequestConfig);
      }
    }
    return null;
  }

  protected renderMain(): ReactElement {
    return <StyledContainer size="sm" style={{padding: PD_XLG}}>
      <Card>
        <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG, borderTop: THEME_COLOR_PRIMARY + " 8px solid"}}>
          <Typography variant="h4">Login successful.</Typography>
          <Typography>You are logged in to your Fyneapps account. You can immediately close this window and continue
            where
            you left.</Typography>
          {this.state.timeoutSec > 0 ?
            <Typography>{"This window will automatically close in " + this.state.timeoutSec + " seconds."}</Typography> : null}
        </StyledBoxColumn>
      </Card>
    </StyledContainer>;
  }
}
