export enum DrawerItemType {
  TITLE,
  LINK,
}

export type DrawerItem = {
  type: DrawerItemType,
  text: string,
  iconUrl?: string,
  url?: string,
}

export const DRAWER_ITEMS: DrawerItem[] = [
  {
    type: DrawerItemType.TITLE,
    text: "Manage",
  },
  {
    type: DrawerItemType.LINK,
    text: "Admin",
    url: "/admin",
    iconUrl: "@icon/admin_panel_settings",
  },
  {
    type: DrawerItemType.LINK,
    text: "Contacts",
    url: "/contacts",
    iconUrl: "@icon/contacts",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Communication",
  },
  {
    type: DrawerItemType.LINK,
    text: "Chat",
    url: "/chat",
    iconUrl: "@icon/chat",
  },
  {
    type: DrawerItemType.LINK,
    text: "Calls",
    url: "/calls",
    iconUrl: "@icon/call",
  },
  {
    type: DrawerItemType.LINK,
    text: "Mail",
    url: "/mail",
    iconUrl: "@icon/mail",
  },
  {
    type: DrawerItemType.LINK,
    text: "Conversations",
    url: "/conversations",
    iconUrl: "@icon/comment_user",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Docs & Storage",
  },
  {
    type: DrawerItemType.LINK,
    text: "Files",
    url: "/files",
    iconUrl: "@icon/cabinet",
  },
  {
    type: DrawerItemType.LINK,
    text: "Wiki",
    url: "/wiki",
    iconUrl: "@icon/wiki",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Marketing & Sales",
  },
  {
    type: DrawerItemType.LINK,
    text: "CRM",
    url: "/crm",
    iconUrl: "@icon/home_smile",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Productivity",
  },
  {
    type: DrawerItemType.LINK,
    text: "Forms",
    url: "/forms",
    iconUrl: "@icon/dynamic_form",
  },
  {
    type: DrawerItemType.LINK,
    text: "Links",
    url: "/links",
    iconUrl: "@icon/bookmarks",
  },
  {
    type: DrawerItemType.LINK,
    text: "Notes",
    url: "/notes",
    iconUrl: "@icon/sticky_note",
  },
  {
    type: DrawerItemType.LINK,
    text: "Tasks",
    url: "/tasks",
    iconUrl: "@icon/task",
  },
  {
    type: DrawerItemType.LINK,
    text: "Todos",
    url: "/todos",
    iconUrl: "@icon/check",
  },
  {
    type: DrawerItemType.LINK,
    text: "Whiteboard",
    url: "/whiteboard",
    iconUrl: "@icon/shape_line",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Scheduling",
  },
  {
    type: DrawerItemType.LINK,
    text: "Calendar",
    url: "/calendar",
    iconUrl: "@icon/calendar",
  },
];